import { Dispatch, SetStateAction } from 'react';
import {
  ContentDetail,
  ModalDetails,
  RemoteControlOption,
} from '@/ts/interfaces/auth';

export const checkTokenValidity = () => {
  const deviceFlowToken = JSON.parse(
    localStorage.getItem('deviceFlowToken') || 'null'
  );
  if (!deviceFlowToken) {
    return false;
  }

  const tokenExpiryTime =
    (deviceFlowToken.created_at + deviceFlowToken.expires_in) * 1000;
  const currentTime = Date.now();

  if (currentTime >= tokenExpiryTime) {
    localStorage.removeItem('deviceFlowToken');
  }
};

export const colorTranslator = (
  themeColor: string,
  setTranslatedColor: Dispatch<SetStateAction<string>>
) => {
  switch (themeColor) {
    case '#e84924':
      setTranslatedColor('orange');
      break;
    case '#7257E0':
      setTranslatedColor('purple');
      break;
    case '#28336B':
      setTranslatedColor('magento');
      break;
    case '#BBE137':
      setTranslatedColor('lime');
      break;
    case '#ADD5D0':
      setTranslatedColor('winterfresh');
      break;
    case '#ADD5D0-2':
      setTranslatedColor('winterfresh-dark');
      break;
    case '#2A2A2A':
      setTranslatedColor('winterfresh-dark');
      break;
    case '#732255':
      setTranslatedColor('dc');
      break;
    default:
      setTranslatedColor('orange');
  }
};
export const chopTitle = (title: string, amountOfWods: number): string => {
  let replacement = '';
  if (amountOfWods === 1) {
    replacement = title.substring(0, 80);
    if (title.length > 80) replacement += '...';
  } else if (amountOfWods === 3) {
    replacement = title.substring(0, 30);
    if (title.length > 30) replacement += '...';
  } else {
    replacement = title.substring(0, 35);
    if (title.length > 35) replacement += '...';
  }
  return replacement;
};

export const getClassByReservedAthletes = (numReservedAthletes: number) => {
  let className;
  switch (true) {
    case numReservedAthletes >= 13 && numReservedAthletes <= 15:
      className = 'btw13_15';
      break;
    case numReservedAthletes >= 16 && numReservedAthletes <= 20:
      className = 'btw16_20';
      break;
    case numReservedAthletes >= 21 && numReservedAthletes <= 25:
      className = 'btw21_25';
      break;
    case numReservedAthletes >= 26 && numReservedAthletes <= 30:
      className = 'btw26_30';
      break;
    case numReservedAthletes >= 31 && numReservedAthletes <= 35:
      className = 'btw31_35';
      break;
    case numReservedAthletes >= 36 && numReservedAthletes <= 50:
      className = 'btw36_50';
      break;
  }
  return className;
};

export const trimName = (
  first_name: string,
  last_name: string,
  capacity: number
) => {
  let fullName;

  if (capacity >= 31 && first_name.length > 6) {
    fullName = first_name.substring(0, 7) + '...';
  } else {
    fullName = `${first_name} ${last_name.charAt(0)}.`;
  }

  return fullName;
};
const commonContentDetails: Record<string, string> = {
  title: 'NO CONTENT TO DISPLAY',
  buttonText: 'SIGN OUT',
};

export const infoContentDetails: Record<string, ContentDetail> = {
  no_content: {
    ...commonContentDetails,
    description: `This account is not a coach for any organization. Check that this
      account has been made an admin or coach in the organization at
      admin.btwb.com, then please try again.`,
  } as ContentDetail,
  no_tracks: {
    ...commonContentDetails,
    description: `This organization does not have any tracks. Add tracks to your organization at admin.btwb.com, then please try again.`,
  } as ContentDetail,
  no_locations: {
    ...commonContentDetails,
    description: `This organization does not have any locations. Add locations to your organization at admin.btwb.com, then please try again.`,
  } as ContentDetail,
};

export const modalsData: Record<string, ModalDetails> = {
  disconnect_remote: {
    title: 'Disconnect remote?',
    message: 'Are you sure you want to disconnect your remote control?',
    buttonTitles: ['CANCEL', 'DISCONNECT'],
  },
  sign_out_tv: {
    title: 'Sign out TV?',
    message:
      'Are you sure you want to sign out from Workout TV? This will also disconnect all  remote controls.',
    buttonTitles: ['CANCEL', 'SIGN OUT'],
  },
};

export const remoteControlOptions: RemoteControlOption[] = [
  {
    title: 'Disconnect Remote',
    value: 'disconnect_remote',
  },
  {
    title: 'Sign Out TV',
    value: 'sign_out_tv',
  },
];
