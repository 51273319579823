import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Gym,
  AuthState,
  DeviceAuthInfo,
  Organization,
} from '@/ts/interfaces/auth';

const initialState: AuthState = {
  gym: null,
  isContentAccessible: true,
  isTokenUpdated: false,
  isTVDataFetched: false,
  organizations: [],
  organizationId: '',
  deviceCodeInfo: null,
  lastUsedOrganizationId: '',
  isSignOutFlow: false,
  disconnectInitiated: false,
  instanceUUID: '',
  remoteUUID: '',
  isChannelConnected: false,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    disableContentAccess: (state) => {
      state.isContentAccessible = false;
    },
    updateOrganizationId: (state, action: PayloadAction<string>) => {
      state.isTokenUpdated = false;
      state.isTVDataFetched = false;
      state.organizationId = action.payload;
    },
    setTokenUpdated: (state, action: PayloadAction<boolean>) => {
      state.isTokenUpdated = action.payload;
    },
    setTVDataFetched: (state, action: PayloadAction<boolean>) => {
      state.isTVDataFetched = action.payload;
    },
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload;
    },
    setDeviceCodeInfo: (state, action: PayloadAction<DeviceAuthInfo>) => {
      state.deviceCodeInfo = action.payload;
    },
    setLastUsedOrganizationId: (state, action: PayloadAction<string>) => {
      state.lastUsedOrganizationId = action.payload;
    },
    setGymInfo: (state, action: PayloadAction<Gym>) => {
      state.gym = action.payload;
    },
    updateSignOutFlowStatus: (state, action: PayloadAction<boolean>) => {
      state.isSignOutFlow = action.payload;
    },
    updateDisconnectStatus: (state, action: PayloadAction<boolean>) => {
      state.disconnectInitiated = action.payload;
    },
    setInstanceUUID: (state, action: PayloadAction<string>) => {
      state.instanceUUID = action.payload;
    },
    setChannelConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.isChannelConnected = action.payload;
    },
    setRemoteUUID: (state, action: PayloadAction<string>) => {
      state.remoteUUID = action.payload;
    },
  },
});

export const {
  disableContentAccess,
  updateOrganizationId,
  setTokenUpdated,
  setTVDataFetched,
  setLastUsedOrganizationId,
  setOrganizations,
  setDeviceCodeInfo,
  setGymInfo,
  updateSignOutFlowStatus,
  updateDisconnectStatus,
  setInstanceUUID,
  setRemoteUUID,
  setChannelConnectionStatus,
} = auth.actions;

export default auth.reducer;
