import { gql } from '@apollo/client';

export const STS_EXCHANGE_TOKEN = gql`
  mutation wodscreenExchangeTokenMutation(
    $scope: String!
    $subjectToken: String!
  ) {
    sts_exchange_token(
      request: { scope: $scope, subjectToken: $subjectToken }
    ) {
      access_token
    }
  }
`;
