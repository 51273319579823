import { jwtDecode } from 'jwt-decode';
import { signOut } from 'next-auth/react';
import { GET_ORGANIZATIONS_QUERY, HNF_ORGANIZATION } from '@/graphql/queries';
import { STS_EXCHANGE_TOKEN } from '@/graphql/mutations';
import {
  disableContentAccess,
  setDeviceCodeInfo,
  setGymInfo,
  setOrganizations,
  setTokenUpdated,
  updateOrganizationId,
  updateSignOutFlowStatus,
} from '@/redux/slices/auth';
import {
  ExchangeToken,
  FetchDeviceInfo,
  FetchOrganizationsInfo,
  HandleGymId,
  PollForToken,
  RefreshDeviceFlowToken,
  SignOutTV,
} from '@/ts/types';
import {
  HNF_Organization,
  JwtPayload,
  Organization,
  RBAC_Organization,
} from '@/ts/interfaces/auth';

let pollingTimeout: NodeJS.Timeout | null = null;

export const signOutTV: SignOutTV = (dispatch) => {
  dispatch(updateSignOutFlowStatus(true));
  localStorage.removeItem('deviceFlowToken');
  signOut({ redirect: false, callbackUrl: `${window.location.origin}/` });
};

export const pollForToken: PollForToken = async (
  deviceCodeData,
  setDeviceFlowToken
) => {
  try {
    const response = await fetch('/api/poll-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ device_code: deviceCodeData.device_code }),
    });

    const data = await response.json();

    if (data.access_token) {
      localStorage.setItem('deviceFlowToken', JSON.stringify(data));
      setDeviceFlowToken(data);

      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
      }
    } else if (data.error === 'authorization_pending') {
      pollingTimeout = setTimeout(
        () => pollForToken(deviceCodeData, setDeviceFlowToken),
        deviceCodeData.interval * 1000
      );
    } else {
      console.error('Error:', data.error);
    }
  } catch (error) {
    console.error('Error Polling Token Info:', error);
  }
};

export const refreshDeviceFlowToken: RefreshDeviceFlowToken = async (
  setDeviceFlowToken
) => {
  const deviceFlowToken = JSON.parse(
    localStorage.getItem('deviceFlowToken') || 'null'
  );

  try {
    const response = await fetch('/api/refresh-device-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken: deviceFlowToken.refresh_token }),
    });

    const data = await response.json();
    if (data.access_token) {
      localStorage.setItem('deviceFlowToken', JSON.stringify(data));
      localStorage.setItem('jwt', data.access_token);
      setDeviceFlowToken(data);
    }
  } catch (error) {
    console.error('Error Polling Token Info:', error);
  }
};

export const fetchDeviceInfo: FetchDeviceInfo = async (
  dispatch,
  setDeviceInfoFetched,
  setDeviceFlowToken
) => {
  try {
    const response = await fetch('/api/authorize-device', {
      method: 'POST',
    });

    const data = await response.json();

    if (data.device_code) {
      dispatch(setDeviceCodeInfo(data));
      setDeviceInfoFetched(true);
      await pollForToken(data, setDeviceFlowToken);
      setTimeout(
        () =>
          fetchDeviceInfo(dispatch, setDeviceInfoFetched, setDeviceFlowToken),
        data.expires_in * 1000
      );
    } else {
      console.error('No device_code received');
    }
  } catch (error) {
    console.error('Error fetching Device Code Info:', error);
  }
};

export const fetchOrganizationsInfo: FetchOrganizationsInfo = async (
  client,
  dispatch,
  setOrgInfoFetched
) => {
  const jwt = localStorage.getItem('jwt');
  const decoded = jwtDecode(jwt!) as JwtPayload;
  const entity_uuid =
    decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];

  try {
    const { data } = await client.query({
      context: {
        headers: {
          'x-hasura-role': 'user',
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        member_uuid: entity_uuid,
        member_string: entity_uuid,
      },
      query: GET_ORGANIZATIONS_QUERY,
    });
    const { hansnfranz, iam_rbac_organization_user_scope_role } = data;

    const validIAMRoleOrgs = iam_rbac_organization_user_scope_role?.filter(
      (user: RBAC_Organization) =>
        (user.scope === 'adminui_gym_workout_tv' && user.role === 'viewer') ||
        (user.scope === 'organization' && user.role === 'admin')
    );

    const uniqueOrganizations = Array.from(
      new Set([
        ...hansnfranz.organization.map(
          (org: HNF_Organization) => org.entity_uuid
        ),
        ...validIAMRoleOrgs.map(
          (org: RBAC_Organization) => org.organization_id
        ),
      ])
    ).reduce(
      (acc, entityId) => {
        const hnnfOrg = hansnfranz.organization.find(
          (org: HNF_Organization) => org.entity_uuid === entityId
        );
        const iamOrg = validIAMRoleOrgs.find(
          (org: RBAC_Organization) => org.organization_id === entityId
        );

        if (hnnfOrg || iamOrg) {
          acc.push(hnnfOrg || iamOrg);
        }

        return acc;
      },
      [] as (HNF_Organization | RBAC_Organization)[]
    );

    const finalOrganizations: Organization[] = uniqueOrganizations.map(
      (organization: RBAC_Organization | HNF_Organization) => {
        const organization_id =
          (organization as HNF_Organization).entity_uuid ??
          (organization as RBAC_Organization).organization_id;
        const name =
          (organization as HNF_Organization).name ??
          (organization as RBAC_Organization).hansnfranzfdw_organization?.name;
        const imageUrl =
          (organization as HNF_Organization).remote_organization?.[0]
            ?.imageUrl ??
          (organization as RBAC_Organization).hansnfranzfdw_organization
            ?.remote_organization?.[0]?.imageUrl;

        return {
          organization_id,
          name,
          imageUrl,
        };
      }
    );

    if (finalOrganizations.length) {
      dispatch(setOrganizations(finalOrganizations));
      dispatch(updateOrganizationId(finalOrganizations[0].organization_id));
    } else {
      dispatch(disableContentAccess());
    }

    setOrgInfoFetched(true);
  } catch (error) {
    console.error('Error fetching Organization data:', error);
  }
};

export const exchangeToken: ExchangeToken = async (
  client,
  dispatch,
  organizationId
) => {
  const jwt = localStorage.getItem('jwt');

  try {
    const { data } = await client.mutate({
      context: {
        headers: {
          'x-hasura-role': 'user',
          authorization: `Bearer ${jwt}`,
        },
      },
      variables: {
        subjectToken: jwt,
        scope: `cap:screen_display#organization:${organizationId}`,
      },
      mutation: STS_EXCHANGE_TOKEN,
    });

    localStorage.setItem('token', data?.sts_exchange_token?.access_token);
    dispatch(setTokenUpdated(true));
  } catch (err) {
    console.error('exchangeToken---error: ', err);
  }
};

export const handleGymId: HandleGymId = (client, dispatch, organizationId) => {
  const token = localStorage.getItem('token');

  const headers = {
    'x-hasura-role': 'cap_screen_display_organization',
    'x-hasura-org-id': organizationId,
    authorization: `Bearer ${token}`,
  };

  client
    .query({
      context: {
        headers: headers,
      },
      variables: {
        id: organizationId,
      },
      query: HNF_ORGANIZATION,
    })
    .then(({ data }) => {
      const gym = data?.hansnfranz?.organization[0]?.remote_organization[0];
      dispatch(setGymInfo(gym));
    })
    .catch((error) => {
      console.error('Failed to fetch Gym data:', error);
    });
};
